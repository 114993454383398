<template>
  <div class="page-listings">
    <cashmanagement-tour v-if="$store.state.tour & !loading" />
    <section
      v-if="loading || !invoices"
      class="pt-5 pb-4 bg9 bs-shadow mobile-top"
      style="min-height: 214px"
    >
      <div class="container">
        <h1 class="text-white mb0 lh1">Loading Cash Management....</h1>
        <br />
        <div class="hollow-dots-spinner">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </div>
      </div>
    </section>

    <section v-else class="pt-5 pb-4 bg9 bs-shadow mobile-top">
      <div class="container">
        <input
          id="cash-step-1"
          class="top-search bs-large mt0"
          type="text"
          placeholder="Search Cash Management by AC Name..."
          v-model="searchValue"
          @keyup.enter.prevent="searchQuery"
        />
        <div class="search-btns">
          <div class="left-side">
            <div id="cash-step-2" class="ac-select">
              <select
                class="form-control"
                id="exampleFormControlSelect1"
                v-model="acFilter"
              >
                <option :value="null" selected class="all-contractors">
                  Area Contractor
                </option>
                <option v-for="ac in acs" :key="ac.acid" :value="ac.acid">
                  {{ " " + toUpperCase(ac.acName) + " (" + ac.acid + ")" }}
                </option>
                <!--
				>{{ " " + ac.acName + " (" + ac.acid + ")" }}</option>
                -->
              </select>
            </div>
          </div>

          <div class="right-side">
            <button
              v-if="searchValue || acFilter"
              @click.prevent="searchQuery"
              class="search"
            >
              <span class="material-icons">search</span>
              {{ " " }} Search
            </button>
            <button v-else class="search" disabled>
              <span class="material-icons">search</span>
              {{ " " }} Search
            </button>
            <button
              v-if="searchValue || acFilter || searching"
              @click="clearSearch"
              class="clear"
            >
              <span class="material-icons">find_replace</span>
              {{ " " }} Clear
            </button>
            <button v-else disabled class="clear">
              <span class="material-icons">find_replace</span>
              {{ " " }} Clear
            </button>
          </div>
        </div>
      </div>
    </section>

    <div class="container">
      <div class="row">
        <div
          v-if="loading == true"
          class="col-xs-12 col-sm-12 col-12 col-lg-12"
        >
          <div class="flex-box-wrap">
            <div class="flex-internal">
              <atom-spinner
                :animation-duration="1000"
                :size="40"
                :color="'#575757'"
              />
              <p>Loading......</p>
            </div>
          </div>
        </div>

        <div
          v-else-if="loading == false && !filteredInvoices.length"
          class="col-xs-12 col-sm-12 col-12 col-lg-12 flexVHcenter"
        >
          <div class="sorry-box">
            <img src="@/assets/img/sad.svg" />
            <h3>Sorry, no invoice was found...</h3>
            <button class="btn-primary" @click="firstLoad">
              Show me some Invoices
            </button>
          </div>
        </div>

        <div v-else class="col-xs-12 col-sm-12 col-12 col-lg-12">
          <div
            class="
              invoice-header
              d-flex
              align-items-center
              p-3
              mt-3
              mb10
              rounded
              box-shadow
            "
          >
            <p class="mrauto mb0">
              Page {{ currPage }} of
              {{ Math.ceil(this.filteredInvoices.length / 200) }}
            </p>
            <div class="pag-wrapper mlauto">
              <div id="cash-step-3" class="nav-btns">
                <button v-if="this.currPage === 1" disabled>
                  <i class="material-icons">keyboard_arrow_left</i> Back
                </button>

                <button v-else @click.prevent="setPage(currPage - 1)">
                  <i class="material-icons">keyboard_arrow_left</i> Back
                </button>

                <button
                  v-if="
                    this.currPage === this.totalPage ||
                    this.currPage ==
                      Math.ceil(this.filteredInvoices.length / 200)
                  "
                  disabled
                >
                  Next
                  <i class="material-icons">keyboard_arrow_right</i>
                </button>

                <button v-else @click.prevent="setPage(currPage + 1)">
                  Next
                  <i class="material-icons">keyboard_arrow_right</i>
                </button>
              </div>
            </div>
          </div>

          <div>
            <div class="listing">
              <!--
          <div v-for="invoice in invoices" :key="invoice.Tid">
              -->
              <h2 class data-config-id="header">AC Aging Report</h2>
              <div class="rfr table-responsive">
                <table class="table-striped table-bordered">
                  <thead>
                    <tr>
                      <th
                        @click="sortCol('acName')"
                        class="table-link forty"
                        :class="columnSort == 'acName' ? 'column-active' : ''"
                        scope="col"
                      >
                        <span style="display: flex; flex: 0 0 100%">
                          <span>AC Name </span>
                          <span class="ml-auto">
                            <i
                              :class="
                                columnSort == 'acName'
                                  ? 'carrot-icon-active'
                                  : ''
                              "
                              class="material-icons carrot-icon"
                            >
                              filter_list
                            </i>
                          </span>
                        </span>
                      </th>
                      <th
                        @click="sortCol('bucket1')"
                        class="table-link ten"
                        :class="columnSort == 'bucket1' ? 'column-active' : ''"
                        scope="col"
                      >
                        <span style="display: flex; flex: 0 0 100%">
                          <span>0-30 </span>
                          <span class="ml-auto">
                            <i
                              :class="
                                columnSort == 'bucket1'
                                  ? 'carrot-icon-active'
                                  : ''
                              "
                              class="material-icons carrot-icon"
                            >
                              filter_list
                            </i>
                          </span>
                        </span>
                      </th>
                      <th
                        @click="sortCol('bucket2')"
                        class="table-link ten"
                        :class="columnSort == 'bucket2' ? 'column-active' : ''"
                        scope="col"
                      >
                        <span style="display: flex; flex: 0 0 100%">
                          <span>31-45 </span>
                          <span class="ml-auto">
                            <i
                              :class="
                                columnSort == 'bucket2'
                                  ? 'carrot-icon-active'
                                  : ''
                              "
                              class="material-icons carrot-icon"
                            >
                              filter_list
                            </i>
                          </span>
                        </span>
                      </th>
                      <th
                        @click="sortCol('bucket3')"
                        class="table-link ten"
                        :class="columnSort == 'bucket3' ? 'column-active' : ''"
                        scope="col"
                      >
                        <span style="display: flex; flex: 0 0 100%">
                          <span>46-70 </span>
                          <span class="ml-auto">
                            <i
                              :class="
                                columnSort == 'bucket3'
                                  ? 'carrot-icon-active'
                                  : ''
                              "
                              class="material-icons carrot-icon"
                            >
                              filter_list
                            </i>
                          </span>
                        </span>
                      </th>
                      <th
                        @click="sortCol('bucket4')"
                        class="table-link ten"
                        :class="columnSort == 'bucket4' ? 'column-active' : ''"
                        scope="col"
                      >
                        <span style="display: flex; flex: 0 0 100%">
                          <span>71+ </span>
                          <span class="ml-auto">
                            <i
                              :class="
                                columnSort == 'bucket4'
                                  ? 'carrot-icon-active'
                                  : ''
                              "
                              class="material-icons carrot-icon"
                            >
                              filter_list
                            </i>
                          </span>
                        </span>
                      </th>
                      <th
                        @click="sortCol('total')"
                        class="table-link twenty"
                        :class="columnSort == 'total' ? 'column-active' : ''"
                        scope="col"
                      >
                        <span style="display: flex; flex: 0 0 100%">
                          <span>Total </span>
                          <span class="ml-auto">
                            <i
                              :class="
                                columnSort == 'total'
                                  ? 'carrot-icon-active'
                                  : ''
                              "
                              class="material-icons carrot-icon"
                            >
                              filter_list
                            </i>
                          </span>
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-bind:key="invoice.acid"
                      v-for="(invoice, index) in filteredInvoices.slice(
                        pageStart,
                        pageStart + countOfPage
                      )"
                    >
                      <td>
                        <router-link v-if="invoice.total != 0"
                          :to="/cash-management/ + invoice.acid"
                          :id="index === 0 ? 'cash-step-4' : ''"
                          >{{
                            invoice.acName + " (" + invoice.acid + ")"
                          }}</router-link
                        >
                        <span v-else>{{invoice.acName}}</span>
                        
                      </td>
                      <td>
                        <p class="mb5">{{ invoice.bucket1 | currency }}</p>
                      </td>
                      <td>
                        <p class="mb5">{{ invoice.bucket2 | currency }}</p>
                      </td>
                      <td>
                        <p class="mb5">{{ invoice.bucket3 | currency }}</p>
                      </td>
                      <td>
                        <p class="mb5">{{ invoice.bucket4 | currency }}</p>
                      </td>
                      <td>
                        <p class="mb5">{{ invoice.total | currency }}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- card -->
            </div>
          </div>

          <div
            class="
              invoice-header
              d-flex
              align-items-center
              p-3
              mb-3
              mt10
              rounded
              box-shadow
            "
          >
            <p class="mrauto mb0">
              Page {{ currPage }} of
              {{ Math.ceil(this.filteredInvoices.length / 200) }}
            </p>
            <div class="pag-wrapper mlauto">
              <div class="nav-btns">
                <button v-if="this.currPage === 1" disabled>
                  <i class="material-icons">keyboard_arrow_left</i> Back
                </button>

                <button v-else @click.prevent="setPage(currPage - 1)">
                  <i class="material-icons">keyboard_arrow_left</i> Back
                </button>

                <button
                  v-if="
                    this.currPage === this.totalPage ||
                    this.currPage ==
                      Math.ceil(this.filteredInvoices.length / 200)
                  "
                  disabled
                >
                  Next
                  <i class="material-icons">keyboard_arrow_right</i>
                </button>

                <button v-else @click.prevent="setPage(currPage + 1)">
                  Next
                  <i class="material-icons">keyboard_arrow_right</i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- end other column -->
      </div>
    </div>
    <!-- container -->
  </div>
</template>

<script>
import axios from "axios";
import { AtomSpinner } from "epic-spinners";
import Avatar from "vue-avatar";
import Datepicker from "vuejs-datepicker";
import cashmanagementTour from "../components/Tours/cashmanagementTour";

const auth = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("SSCUSER"),
    //Authorization: "Bearer " + wontworkqwd2iwedjqoewiudwo
  },
};

export default {
  name: "CashManagement",
  components: {
    AtomSpinner,
    Avatar,
    Datepicker,
    cashmanagementTour,
  },
  data() {
    return {
      ordermanageapi: process.env.VUE_APP_ORDER_MANAGE_API,
      backend: process.env.VUE_APP_SERVER_URL,
      searchValue: "",
      search: "",
      storedListings: [],
      searchRender: false,
      searchStarted: false,
      acs: [],
      invoices: [],
      selectedCat: "Show All",
      acFilter: "",
      searching: false,
      loading: false,
      countOfPage: 200,
      currPage: 1,
      noRGAFound: false,
      forumCats: [
        { id: 0, name: "Show All" },
        { id: 1, name: "Configura" },
        { id: 2, name: "Marketing" },
        { id: 3, name: "Other" },
        { id: 4, name: "Process" },
        { id: 5, name: "Product" },
        { id: 6, name: "Spacenet" },
      ],
      colSort: false,
      columnSort: "",
    };
  },
  methods: {
    firstLoad: function () {
      this.searchValue = null;
      this.acFilter = null;
      this.searchStarted = false;
      this.$store.commit("setActivePage", "order-manage");
      this.acListSync();
      this.cashSync();
    },
    cashSync: function () {
      if (!this.$store.state.cashManagement.cashManageList.length) {
        this.loading = true;
        //console.log("getting eos from component");
        return axios
          .get(`${this.ordermanageapi}/cashmanagement/`, auth)
          .then((response) => {
            let sorted = response.data.aging.sort((a, b) =>
              a.acName > b.acName ? 1 : -1
            );
            this.invoices = sorted;
            this.loading = false;
            this.$store.commit("UPDATE_CASH", this.invoices);
          });
      } else {
        //console.log("vuex has length");
        this.invoices = this.$store.state.cashManagement.cashManageList;
        this.loading = false;
      }
    },

    acListSync: function () {
      if (!this.$store.state.acList.acList.length) {
        return axios
          .get(`${this.ordermanageapi}/aclist?active=true`, auth)
          .then((response) => {
            //console.log(response);
            const data = response.data;
            /*

            const low = data.filter(function (e) {
              return e.acid < 210;
            });

            const mid = data.filter(function (e) {
              return e.acid == 80069;
            });

            const high = data.filter(function (e) {
              return e.acid > 800000 && e.acid < 800250;
            });

            const all = low.concat(high).concat(mid);
            const alph = all.sort((a, b) => (a.acName > b.acName ? 1 : -1));

            this.acs = alph;
            */
            const alph = data.sort((a, b) => (a.acName > b.acName ? 1 : -1));
            this.acs = alph;
            this.$store.commit("UPDATE_ACLIST", this.acs);
          });
      } else {
        this.acs = this.$store.state.acList.acList;
      }
    },

    toUpperCase: function (str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.toUpperCase();
      });
    },

    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    searchReset: function () {
      this.selectedCat = "Show All";
    },

    setPage: function (idx) {
      this.search = "";
      if (idx <= 0 || idx > this.totalPage) {
        return;
      }
      this.currPage = idx;
    },

    catSwitcher: function (catType) {
      //console.log(catType);
      let category = catType;
      console.log(category);
      if (category !== "Show All") {
        this.selectedCat = category;
        this.search = "";
        //console.log(category);
      } else {
        this.selectedCat = "Show All";
      }
    },

    searchQuery(event) {
      this.searchStarted = true;
      if (this.searchStarted === true) {
        this.storedListings = this.invoices;
      }

      this.search = "";
      this.loading = true;
      this.searching = true;

      return axios.get(this.searchEndpointCompute, auth).then((response) => {
        this.invoices = response.data.aging;
        this.searchValue = "";
        this.acFilter = null;
        this.loading = false;
      });
    },

    clearSearch() {
      if (this.searchStarted == true) {
        this.invoices = this.storedListings;
        this.searchStarted = false;
        this.searchRender = !this.searchRender;
      }

      //this.listings = this.storedListings;

      this.searchValue = "";
      this.searching = false;
      this.acFilter = null;
      this.$forceUpdate();

      //this.$forceUpdate();
      //Vue.set(this.listings, this.storedListings);
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    sortCol: function (type) {
      this.columnSort = type;
      const COLUMN = type;
      console.log(COLUMN);
      if (this.colSort) {
        this.invoices.sort((a, b) => (a[COLUMN] > b[COLUMN] ? 1 : -1));
      } else {
        this.invoices.sort((a, b) => (a[COLUMN] < b[COLUMN] ? 1 : -1));
      }
      this.colSort = !this.colSort;
    },
  },
  computed: {
    activeNav() {
      return this.$store.state.activePage;
    },

    colorGetter() {
      return this.$store.state.colors;
    },

    filteredInvoices: function () {
      //set the current page to 0
      this.currPage = 1;
      //if the search box is filled in
      if (this.search.trim() !== "") {
        return this.invoices.filter((e) => {
          //filter the subject line by what is filled in the search box
          return (
            e.OrderNumber.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          );
        });
      } else if (this.selectedCat !== "Show All") {
        this.search = "";
        //console.log(this.selectedCat);
        return this.invoices.filter((f) => {
          return f.Type == this.selectedCat;
        });
      } else {
        //return this.invoices;
        if(this.employee){
          return this.invoices.filter((f) => {
            return f.total != 0;
          });
        } else {
          return this.invoices;
        }

        
        /*
        return this.invoices.filter((f) => {
          return f.total != 0;
        });
        */
      }
    },

    employee() {
      return this.$store.getters.isSpacesaverEmployee;
    },

    foundNoRGA: function () {
      if (this.search.length > 1 && this.filteredInvoices.length === 0) {
        //this.noUserFound = true;
        return true;
      }
    },

    pageStart: function () {
      return (this.currPage - 1) * this.countOfPage;
    },

    totalPage: function () {
      return Math.ceil(this.invoices.length / this.countOfPage);
    },
    listingRestore: function () {
      if (
        !this.searchValue &&
        !this.loading &&
        !this.searching &&
        this.storedListings.length >= this.invoices
      ) {
        //console.log("fired off");
        this.invoices = this.storedListings;
      }
      return;
    },
    searchEndpointCompute: function () {
      //has order number, no start, no end, no ac
      if (this.searchValue && !this.acFilter) {
        return `${this.ordermanageapi}/cashmanagement?search=${this.searchValue.trim()}`;
      } else if (
        //has order number, and start, no end, no ac
        //!this.searchValue &&
        this.acFilter
      ) {
        return `${this.ordermanageapi}/cashmanagement/${this.acFilter}`;
      } else {
        return `${this.ordermanageapi}/cashmanagement`;
      }
    },
    clearSearchValue: function () {
      if (this.acFilter != null) {
        this.searchValue = "";
      }
    },
  },
  created() {
    this.$store.commit("showNavigation");
    // console.log(this.$route.path);
  },

  mounted() {
    this.firstLoad();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media all and (min-width: 992px) {
  .flex-box-wrap {
    height: 200px;
    padding: 150px 0 125px 0;
    margin: 0;
  }
}

@media all and (max-width: 991px) {
  .flex-box-wrap {
    height: 100px;
    padding: 75px 0 50px 0;
    margin: 0;
    justify-content: center;
    align-items: center;
  }
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #00b4b4;
  border-color: #00b4b4;
}

.invoice {
  background-color: #fff;
  line-height: 44px;
  padding: 20px;
  margin-bottom: 5px;
  border-top: 0;
}
.top-wrap {
  display: flex;
  align-items: flex-start;
}
.invoice-bottom {
  align-items: flex-end;
}
.invoice-header {
  background: #fff;
  color: #575757;
  display: flex !important;
}
.small-lh {
  line-height: 1.4em;
}

.listing {
  overflow: scroll;
}

.listing {
  background-color: #fff;
  line-height: 1;
  padding: 20px;
  margin-bottom: 5px;
  border-top: 0;
}

.table-responsive {
  display: table;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.03) !important;
}
.table-bordered td,
.table-bordered th {
  border: 1px solid #e8e8e8 !important;
}
.badge-success {
  color: #fff;
  background-color: #4d5967;
  background-color: #1eb5b6;
}
.table-striped a {
  color: #00b4b4;
}
.table-striped td,
.table-striped th {
  padding: 5px;
  font-weight: 300 !important;
}
.listing {
  border-radius: 4px;
}
table td {
  line-height: 1;
}
.table-striped th {
  background: #00b4b4;
  background: #4d5967;
  color: #fff;
}
.table-striped {
  min-width: 100%;
  overflow: scroll;
}
.search-btns {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}
.search-btns button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: none;
  /* background: #519bb2; */
  background: #4d5967;
  color: #fff;
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;
  max-height: 40px;
  padding: 23px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.search-btns .clear {
  margin-right: 0;
  margin-left: auto;
  padding: 23px 15px;
}
.search-btns .search {
  /*margin-left: auto;*/
  margin-right: 0;
  margin-left: auto;
}
.search-btns button span {
  font-size: 20px;
  width: 20px;
  height: 20px;
  color: #fff;
}
.search-btns button:hover {
  background: #586575;
}
.search-btns button:active {
  background: #586575;
}
.search-btns button:disabled {
  opacity: 0.6;
  cursor: unset !important;
}
.search-btns button:disabled:hover {
  background: #4d5967;
}
.search-btns button:disabled:active {
  background: #4d5967;
}

@media all and (min-width: 768px) {
  .dates {
    display: flex;
    color: #757575 !important;
  }
}
.top-search {
  color: #757575;
}
select.form-control:not([size]):not([multiple]) {
  height: auto !important;
  padding: 10px !important;
  color: #757575;
}
.all-contractors {
  color: #757575 !important;
}
select.form-control:not([size]):not([multiple]) {
  height: auto !important;
  padding: 10px !important;
  color: #757575;
}
.all-contractors {
  color: #757575 !important;
}
.right-side {
  text-align: right;
  flex: 1;
}
@media all and (max-width: 991px) {
  table {
    width: 900px !important;
    overflow: scroll;
  }
}
</style>
